<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-row>
        <v-col cols="12" md="8">
          <v-card-title v-if="userId">Изменить пользователя</v-card-title>
          <v-card-title v-else>Создать пользователя</v-card-title>
        </v-col>
      </v-row>
      <UserForm />
    </v-card>
  </v-main>
</template>

<script>
import UserForm from "@/views/users/UserForm.vue";

export default {
  name: "UserEdit",
  components: {UserForm},
  computed: {
    userId() {
      return this.$route.params.id;
    },
  },
}
</script>

<style scoped>

</style>
