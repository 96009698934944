<template>
  <v-app>
    <v-container>
      <div v-if="loading" class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-card-text v-else>
        <p class="account-text-header">Имя</p>
        <v-text-field
          v-model="user.first_name"
          outlined
          required
          label="* Имя"
          :rules="nonEmptyRules"
        >
        </v-text-field>
        <v-text-field
          v-model="user.last_name"
          outlined
          label="Фамилия"
        >
        </v-text-field>
        <v-text-field
          v-model="user.username"
          outlined
          :rules="usernameSlugRules"
          required
          label="* Никнейм"
        >
        </v-text-field>
        <v-text-field
          v-model="user.email"
          outlined
          :rules="emailRules"
          required
          label="* Email"
        >
        </v-text-field>
        <v-select
          v-model="user.group"
          :rules="nonEmptyRules"
          item-text="role"
          item-value="id"
          :items="filterRolesByUserGroup()"
          outlined
          required
          persistent-hint
          single-line
          prepend-inner-icon="mdi-account"
          label="* Роль"
        >
        </v-select>
        <v-select
          v-model="user.company"
          item-text="name"
          item-value="id"
          :items="companies"
          outlined
          persistent-hint
          single-line
          prepend-inner-icon="mdi-account-group"
          label="Компания"
        >
        </v-select>
        <v-text-field
          v-model="user.phone"
          outlined
          :rules="phoneRules"
          label="* Номер телефона"
        >
        </v-text-field>
        <v-btn
          text
          outlined
          color="dark"
          @click="saveUser"
        >
          Сохранить
        </v-btn>
      </v-card-text>
    </v-container>
  </v-app>
</template>

<script>
import account from "@/api/account";
import {mapGetters} from "vuex";

export default {
  name: "UserForm",
  data() {
    return {
      user: {
        id: null,
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        group: '',
        phone: '',
      },
      roles: [
        {role: 'Суперадминистратор', id: 1},
        {role: 'Администратор', id: 2},
        {role: 'Сотрудник склада', id: 3},
        {role: 'Франчайзи', id: 4},
        {role: 'Директор по франчайзингу', id: 6},
        {role: 'Менеджер по логистике', id: 7},
        {role: 'Менеджер по закупкам', id: 8},
        {role: 'Менеджер маркетплейсов', id: 10},
        {role: 'Менеджер опт хантер', id: 11},
        {role: 'Оптовый партнёр', id: 12},
        {role: 'РОП Опт', id: 13},
        {role: 'Менеджер опт фермер', id: 14},
      ],
      loading: false,
      phoneRules: [
        v => /^\+\d{11}$/.test(v) || 'Номер телефона нужно писать с +'
      ],
      emailRules: [
        v => !!v || 'Это поле нужно заполнить',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат почты'
      ],
      nonEmptyRules: [
        v => !!v || 'Это поле нужно заполнить'
      ],
      usernameSlugRules: [
        v => !!v || 'Это поле нужно заполнить',
        v => /^[a-z0-9]+(?:[-_.][a-z0-9]+)*$/.test(v) || 'Неправильный формат username_slug',
        v => (v && v.length >= 2 && v.length <= 150) || 'Длина должна быть от 2 до 150 символов'
      ],
      companies: []
    };
  },
  computed: {
    ...mapGetters("auth", ["getUsername", "getUserGroup", "isFranchiseeGroup", "isWholeSaleGroup", "isWholesaleHunter",
      "isWholesaleFarmer", "isWholesalePartner", "isPurchasingManager", "isWholesaleROP", "isDirectorOfFranchising", "isSuperUser", "isAdmin", "isStorekeeper"]),
  },
  created() {
    this.loading = true;
    this.getUserInfo();
    this.getCompaniesList();
    this.loading = false;
  },
  methods: {
    filterRolesByUserGroup() {
      let filteredRoles = [];

      if (this.isSuperUser) {
        filteredRoles = this.roles;
      } else if (this.isAdmin) {
        filteredRoles = this.roles.slice(1);
      } else if (this.isWholesaleROP) {
        filteredRoles = this.roles.filter(role => [11, 12, 14].includes(role.id));
      } else if (this.isDirectorOfFranchising) {
        filteredRoles = this.roles.filter(role => role.id === 4);
      }
      filteredRoles = filteredRoles.filter(
        role => role.role !== "Тестовый" && role.role !== "Тренер по продажам"
      );
      return filteredRoles;
    },
    getUserInfo: async function () {

      const userId = this.$route.params.id;
      if (!userId) {
        return;
      }
      try {
        const response = await account.getUser(userId);
        this.user = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    getCompaniesList: async function () {
      const response = await account.getCompanyList();
      this.companies = response.data;
    },
    saveUser: async function () {
      if (!this.validateUserInfo()) {
        return;
      }
      if (this.user.id) {
        await this.updateUser();
      } else {
        await this.createUser();
      }
    },
    validateUserInfo: function () {
      if (this.user.first_name &&
        this.user.email &&
        this.user.username &&
        this.user.group) {
        return true;
      }
      console.log('Пожалуйста, заполните все обязательные поля');
      this.$store.dispatch("alertError", "Пожалуйста, заполните все обязательные поля");
      return false;
    },
    updateUser: async function () {
      try {
        const response = await account.changeUserInfo(this.user);
        this.user = response.data;
        this.$store.dispatch("alertSuccess", "Информация о пользователе успешно сохранена");
        return this.$router.push({ name: 'UserList' });
      } catch (e) {
        console.log(e);
      }
    },
    createUser: async function () {
      try {
        const response = await account.createUserInfo(this.user);
        this.user = response.data;
        this.$store.dispatch("alertSuccess", "Юзер успешно создан");
        return this.$router.push({ name: 'UserList' });
      } catch (e) {
        this.$store.dispatch("alertError", "Юзер c таким никнеймом или почтой уже существует");
      }
    },
  }
}
</script>

<style scoped>

</style>
